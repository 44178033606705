import Plyr from 'plyr';

// Initialize Plyr for YouTube embed
const youtubePlayer = document.querySelector('#player[data-plyr-provider="youtube"]');

// Check if the YouTube player exists
if (youtubePlayer) {
    // Initialize Plyr for YouTube
    new Plyr(youtubePlayer, {
        controls: [
            'play-large', // The large play button in the center
            'play', // Play/pause playback
            'progress', // The progress bar and scrubber for playback and buffering
            'current-time', // The current time of playback
            'duration', // The full duration of the media
            'mute', // Toggle mute
            'volume', // Volume control
            'fullscreen' // Toggle fullscreen
        ],
        settings: [], // Optional: remove or customize settings options
    });
}

// Define the data attribute for videos to be excluded
const EXCLUDED_ATTR = 'data-no-plyr';

// Select all video elements
const allVideoElements = document.querySelectorAll('video');

// Initialize Plyr on each video element except those with the excluded data attribute
allVideoElements.forEach(video => {
    // Check if the video element has the exclusion data attribute
    if (video.hasAttribute(EXCLUDED_ATTR)) {
        return; // Skip this video
    }

    // Check if the video element has the 'controls' attribute
    const hasControls = video.hasAttribute('controls');

    // Initialize Plyr with or without controls based on the video element's 'controls' attribute
    new Plyr(video, {
        controls: hasControls ? [
            'play-large', // The large play button in the center
            // 'restart', // Restart playback
            // 'rewind', // Rewind by the seek time (default 10 seconds)
            'play', // Play/pause playback
            // 'fast-forward', // Fast forward by the seek time (default 10 seconds)
            'progress', // The progress bar and scrubber for playback and buffering
            'current-time', // The current time of playback
            'duration', // The full duration of the media
            'mute', // Toggle mute
            'volume', // Volume control
            // 'captions', // Toggle captions
            // 'settings', // Settings menu
            // 'pip', // Picture-in-picture (currently Safari only)
            // 'airplay', // Airplay (currently Safari only)
            // 'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
            'fullscreen' // Toggle fullscreen
        ] : [], // No controls if the 'controls' attribute is not present
        settings: [],
    });
});
